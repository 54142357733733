import { storeToRefs } from 'pinia';
import { format } from 'quasar';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormMarketability from '@/components/ListingPage/Form/LPFormMarketability.vue';
import { useApiListing } from '@/composables/api/listing';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import { useUtmSource } from '@/composables/useUtmSource';
import configApp from '@/config/app.json';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpMarketability = () => {
  const factoryId: FactoryId = 'lp-marketability';

  const { storeMarketabilityReport } = useApiListing();
  const { authMeta, dialogAuth, dialogFactoryId, user } = storeToRefs(useAuthStore());
  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpMarketability = (): FormStepsFactory => {
    const { listing, listingData } = storeToRefs(useListingStore());

    const subtitle = computed(() => {
      const listingBuildYear = listingData.value?.propertyInfo.building?.buildYear;
      const listingLocation = listing.value?.address.locations
        .slice(0, 2)
        .map(i => format.capitalize(i))
        .join(', ');

      if (listingBuildYear && listingLocation) {
        return t('subtitle.marketability', {
          listingBuildYear,
          listingLocation,
        });
      }

      return t('subtitle.marketabilityFallback');
    });

    const submit = async () => {
      if (!listing.value) throw new Error('Listing is undefined');

      const { resolveUtmSource } = useUtmSource();
      const { getToken } = useCaptcha();

      const rToken = await getToken('submitMarketability');

      return storeMarketabilityReport({
        listingId: listing.value.id,
        utmSource: resolveUtmSource(),
        r_token: rToken,
      }).then(() => {
        useGTM('request_marketability_report');
      });
    };

    return computed<Step[]>(() => [
      {
        component: () => LPFormMarketability,
        formId: 'lp-marketability',
        onNextCallback: (next, state) => {
          if (!user.value) {
            dialogFactoryId.value = factoryId;
            authMeta.value.email.title = t('title.marketabilityLogin');
            authMeta.value.email.subtitle = t('subtitle.marketabilityLogin');

            dialogAuth.value = true;

            watch(
              dialogAuth,
              () => {
                if (user.value) {
                  state.onNext(next);
                }
              },
              { once: true }
            );
          } else {
            state.onNext(next);
          }
        },
        subtitle: subtitle.value,
        title: t('title.marketability'),
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          onMountedCallbackFn: submit,
          formInfoMsgPropsError: {
            subtitle: { text: t('subtitle.marketabilityError') },
            title: { text: t('title.marketabilityError') },
          },
          formInfoMsgPropsSuccess: {
            subtitle: {
              text: t('subtitle.marketabilitySuccess', { company: configApp.company.name }),
            },
            title: { text: t('title.marketabilitySuccess') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpMarketability };
};
